import React, { Component } from 'react';
import Courses from './components/courses/Courses';
import './App.css';

export default class App extends Component {
    render() {
        return (
            <Courses
                searchPlaceholder={this.props.searchPlaceholder}
                printBtnText={this.props.printBtnText}
            />
        );
    }
}
