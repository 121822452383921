import React, { Component } from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import './courses.css';
import Course from '../course/Course';
export default class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFilter: '',
            courses: null,
            filteredCourses: null,
            all: false,
        };
        this.printables = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { searchFilter } = this.state;
        let courses = this.state.courses;

        if (searchFilter !== prevState.searchFilter) {
            courses = this.state.courses.filter((course) => {
                const re = new RegExp(searchFilter, 'i');
                return (
                    re.test(course.prefix) ||
                    re.test(course.courseNumber) ||
                    re.test(course.title) ||
                    re.test(`${course.prefix}${course.courseNumber}`) ||
                    re.test(`${course.prefix} ${course.courseNumber}`)
                );
            });
            this.setState({ filteredCourses: courses });
        }
    }

    componentDidMount() {
        this.loadCourses();
    }

    async loadCourses() {
        await fetch('https://www.irsc.edu/_resources/data/de-courses.json')
            .then((res) => res.json())
            .then((json) => {
                this.setState({ courses: json.data.courses });
            });
    }

    toggleAll = (event) => {
        this.setState({ all: event.target.checked });
    };

    handleChange = (event) => {
        this.setState({ searchFilter: event.target.value });
    };

    getCourses = () => {
        let courses = this.state.filteredCourses || this.state.courses;
        const { searchFilter, all } = this.state;

        if (!courses) {
            return 'Loading...';
        }

        if (!all && searchFilter === '') {
            courses = courses.slice(0, 10);
        }

        courses = courses.sort((a, b) =>
            a.prefix > b.prefix ? 1 : a.courseNumber > b.courseNumber ? 1 : -1
        );
        // console.log('displaying courses....');
        // console.log(courses);
        return courses.map((course, idx) => {
            const key = `${course.prefix}-${course.courseNumber}-${idx}`;
            return (
                <Course
                    key={key}
                    prefix={course.prefix.trim()}
                    courseNumber={course.courseNumber.trim()}
                    level={course.level.trim()}
                    number={course.number.trim()}
                    title={course.title.trim()}
                    subjectAreaRequirement={course.subjectAreaRequirement.trim()}
                    creditAwarded={course.creditAwarded.trim()}
                />
            );
        });
    };

    showPrintables = () => {
        console.log('Logging out before print but after click...');
        const printables = document.querySelectorAll('.print-show');
        printables.forEach((el) => {
            if (el.classList.contains('no-mobile')) {
                this.printables.push(el);
                el.classList.toggle('no-mobile');
            }
        });
    };

    hidePrintables = () => {
        this.printables.forEach((el) => {
            el.classList.toggle('no-mobile');
        });
        this.printables = [];
    };

    render() {
        return (
            <div id='de-courses'>
                <div className='search-container'>
                    <div className='search-filter'>
                        <input
                            className=''
                            type='text'
                            id='filter'
                            value={this.state.searchFilter}
                            onChange={this.handleChange}
                            placeholder='Search Dual Enrollment Courses...'
                        />
                    </div>
                    <div id='search-filters' className='search-filters'>
                        <div id='filter-all' className='filter-toggle'>
                            <input
                                role='switch'
                                className='tgl tgl-light'
                                id='toggle-all'
                                type='checkbox'
                                checked={!!this.state.all}
                                onChange={this.toggleAll}
                                aria-labelledby='toggle-all-label'
                            />
                            <label
                                id='toggle-all-label'
                                aria-label='Toggle All.'
                                className='tgl-btn'
                                htmlFor='toggle-all'
                            ></label>
                            <span className=''>All</span>
                        </div>
                    </div>

                    <ReactToPrint
                        content={() => this.componentRef}
                        onBeforeGetContent={() => this.showPrintables()}
                        onAfterPrint={() => this.hidePrintables()}
                    >
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <button className='btn' onClick={handlePrint}>
                                    Print Courses
                                </button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
                <div
                    className='padding print-courses'
                    ref={(el) => (this.componentRef = el)}
                >
                    <div className='course-header no-mobile print-show'>
                        <div className='field prefix'>Course Prefix</div>
                        <div className='field course-number'>Course Number</div>
                        {/* <div className='field level'>Level</div> */}
                        {/* <div className='field number'>Number</div> */}
                        <div className='field title'>Title</div>
                        {/* <div className='field subject-area-requirement'>
                            Subject Area Requirement
                        </div> */}
                        <div className='field credit-awarded'>
                            H.S. Credit Awarded
                        </div>
                    </div>
                    {this.getCourses()}
                </div>
            </div>
        );
    }
}
