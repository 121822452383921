import React, { Component } from 'react';
import './course.css';

export default class Course extends Component {
    render() {
        return (
            <div className='course-header'>
                <div className='field prefix'>
                    <div className='mobile-only'>Course Prefix</div>
                    <div>{this.props.prefix}</div>
                </div>
                <div className='field course-number'>
                    <div className='mobile-only'>Course Number</div>
                    <div>{this.props.courseNumber}</div>
                </div>
                {/* <div className='field level'>
                    <div className='mobile-only'>Level</div>
                    <div>{this.props.level}</div>
                </div> */}
                {/* <div className='field number'>
                    <div className='mobile-only'>Number</div>
                    <div>{this.props.number}</div>
                </div> */}
                <div className='field title'>
                    <div className='mobile-only'>Title</div>
                    <div>{this.props.title}</div>
                </div>
                {/* <div className='field subject-area-requirement'>
                    <div className='mobile-only'>Subject Area Requirement</div>
                    <div>{this.props.subjectAreaRequirement}</div>
                </div> */}
                <div className='field credit-awarded'>
                    <div className='mobile-only'>H.S. Credit Awarded</div>
                    <div>{this.props.creditAwarded}</div>
                </div>
            </div>
        );
    }
}
