import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reactToWebComponent from 'react-to-webcomponent';
import PropTypes from 'prop-types';

App.propTypes = {
    searchPlaceholder: PropTypes.string,
    printBtnText: PropTypes.string,
};

customElements.define(
    'irsc-de-courses',
    reactToWebComponent(App, React, ReactDOM)
);
